<template>
  <div id="app" width="1000px">
    <v-btn color="white" class="font-rubik btn ml-16 mb-2 text-capitalize font-weight-black text-color"
      :loading="getUserRiversStatus_Pending" @click="handleSavedRivers()">
      See Registered Data
    </v-btn>

    <!-- Displays expansion panels with the saved rivers, their alternative names, their polygons and every saved image with metadata -->
    <v-dialog v-model="showDialog" v-if="getUserRiversStatus_Success" width="90%">
      <v-expansion-panels v-model="panel" accordion v-if="rivers.length > 0 && (screenWidth > 560)" class="font-rubik btn">
        <v-expansion-panel v-for="river in paginatedRivers" :key="river.river_id"
          @click="getRiverPolygonMetada(river.river_id)" @change="loading = true">
          <v-expansion-panel-header>
            <span class="text-color font-weight-black">{{ river.river_name }}</span>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="text-center">
            <v-progress-linear
              v-if="getUserPolygonsStatus_Pending && loading"
              :height="4"
              color="primary"
              indeterminate
              rounded
              >
            </v-progress-linear>
            <ExpansionPanelPolygon    
              v-if="!loading"          
              :polygons="polygons" 
              :riverid="river.river_id"
              @metadataUpdated="getRiverPolygonMetada"
              class="mx-auto" open />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-card width="100%">
          <div class="d-flex justify-center my-2 pagination">
            <v-btn color="#19A7CE" class="white--text text-capitalize mr-2" @click="goToPreviousPage"
              :disabled="currentPage === 1">Previous</v-btn>
            <v-btn color="#19A7CE" class="white--text text-capitalize" @click="goToNextPage"
              :disabled="currentPage === totalPages">Next</v-btn>
          </div>
        </v-card>
      </v-expansion-panels>
      <v-card v-else-if="rivers.length === 0 && (screenWidth > 560)" class="z-index">
        <v-card-title class="text-color font-rubik d-flex justify-center">You have no metadata saved</v-card-title>
        <v-card-actions>
          <v-btn color="#19A7CE" class="white--text margin" @click="showDialog = !showDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="rivers.length > 0 && (screenWidth < 560)" class="z-index">
        <v-card-title class="text-break">
          Please rotate your device horizontally to see the results
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { getUserPolygons } from "@/api/polygonApi.js";
import { getUserRivers } from "@/api/userApi.js";
import { deleteImageEntry } from "@/api/metadataApi.js";
import ExpansionPanelPolygon from '@/views/RiverTemp/ExpansionPanelPolygon.vue';


export default {
  name: "NewMetadataCards",
  components: { ExpansionPanelPolygon },

  data() {
    return {
      noInfo: "There are no metadata saved",
      currentPage: 1,
      itemsPerPage: 5,
      rivers: [],
      polygons: [],
      showDialog: false,
      expand: false,
      userMetadata: null,
      polygonIds: null,
      screenWidth: null,
      getUserRiversStatus: apiStatus.Idle,
      getUserPolygonsStatus: apiStatus.Idle,
      loading : false,
      panel: null
    }
  },

  computed: {
    ...apiStatusComputed([
      "getMetadata",
      "getPolygons",
      "getImage",
      "validateImageUrl",
      "getUserRiversStatus",
      "getUserPolygonsStatus"
    ]),

    paginatedRivers() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.rivers.slice(startIndex, endIndex);
    },

    totalPages() {
      return Math.ceil(this.rivers.length / this.itemsPerPage);
    },
  },

  methods: {
    // Get all saved rivers of the user
    async getSavedRivers() {
      this.getUserRiversStatus = apiStatus.Pending;
      const { response, error } = await withAsync(getUserRivers);
      if (error) {
        console.log(error);
        this.getUserRiversStatus = apiStatus.Error;
        this.errorMessage = error.response.data.message;
        return;
      }
      this.getUserRiversStatus = apiStatus.Success;
      return response.data.data;
    },

    async handleSavedRivers() {
      this.rivers = await this.getSavedRivers();
      this.riverNames = this.rivers.map((river) => river.river_name);
      this.showDialog = !this.showDialog;
    },

    // Get the metadata for each polygon of the selected alternative name
    //get User Polygons only
    async getRiverPolygonMetada(riverId) {
      this.getUserPolygonsStatus = apiStatus.Pending;


      let { response, error } = await withAsync(getUserPolygons, riverId);

      if (error) {
        this.getUserPolygonsStatus = apiStatus.Error;
        this.errorMessage = error.response.data.message;
        console.log(error);
        return;
      }

      this.getUserPolygonsStatus = apiStatus.Success;
      this.polygons = response.data.data;
      this.loading = false;
    }, 

    async deleteImgEntry(image_id) {
      const { response, error } = await withAsync(deleteImageEntry, image_id);
      if (error) {
        this.errorMessage = error.response.data.message;
        return;
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },

    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
      this.panel = null; //reset the state of expans panel via v-model
    },

    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
      this.panel = null; //reset the state of expans panel via v-model
    },
  },

  created() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.updateScreenWidth);
  },

  updated() {
    this.screenWidth = window.innerWidth;
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
};
</script>

<style scoped>
.btn {
  z-index: 9999;
}

.font-rubik {
  font-family: 'Rubik', sans-serif;
}

.text-color {
  color: #19A7CE !important;
}

.margin {
  display: flex;
  margin: 0 auto;
}

.height {
  height: 800px;
}

.z-index {
  z-index: 9999;
}

.small-device {
  height: 1150px;
}

.small-img {
  display: flex;
  margin-right: 50%;
}

>>>.v-dialog--active {
  display: flex !important;
  margin: 0 auto !important;
}

>>>.mdi-chevron-down::before,
>>>.mdi-chevron-down::after,
>>>.mdi-chevron-up::before,
>>>.mdi-chevron-up::after {
  color: #19A7CE !important;
}

>>>.mdi-chevron-right::before,
>>>.mdi-chevron-left::before {
  background-color: white !important;
  color: #19A7CE !important;
}

>>>.mdi-minus::before {
  color: #19A7CE !important;
}

>>>.theme--light.v-text-field>.v-input__control>.v-input__slot:before,
>>>.theme--light.v-text-field>.v-input__control>.v-input__slot:after {
  border-color: white !important;
}

@media only screen and (max-width:600px) {
  .small-img {
    width: 50%;
  }
}
</style>