export default {
  setAvailableDates(context, dates){
    context.commit("setAvailableDates", dates);
  },

  setRiverShapeCoordinates(context, coordinates) {
    context.commit("updateShapeCoords", coordinates);
  },

  updateSavedDates(context, savedDates) {
    context.commit("updateSavedDates", savedDates);
  },

  setPolygonId(context, polygonId) {
    context.commit("setPolygonId", polygonId);
  },

  setPolygonsOnBbox(context, polygonsOnBbox) {
    context.commit("setPolygonsOnBbox", polygonsOnBbox);
  },

  setShowPolygons(context, showPolygons) {
    context.commit("setShowPolygons", showPolygons);  
  },

  setRiverData(context, riverData) {       
    riverData.river_name && context.commit("setRiverName", riverData.river_name);
    riverData.customName && context.commit("setRiverCustomName", riverData.customName);
    riverData.showCustomName && context.commit("setShowCustomName", riverData.showCustomName);
    riverData.river_id && context.commit("setRiverId", riverData.river_id);
  }
}