export default {
    getAllUsers( state ) {
        return state.allUsers;
    },

    getAllPolygonsByRiver( state ) {
       return state.allRiverPolygons;
    },

    getSelectedRiver( state ) {
        return state.selectedRiver;
    },
    
    getSelectedRiverName( state ) {
        return state.selectedRiverName;
    }
}