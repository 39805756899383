import { api } from "./api";

const URLS = {
  groundTruthImages: (polygonId) => `polygon/${polygonId}/ground_truth_image`,
};

export const postMetadata = ( payload ) => {
  return api.post(`/metadata`, { ...payload });
};

export const getMetadata = ( polygonId ) => {
  return api.get(`/polygon/${polygonId}/metadata`);
};

export const getImage = ( polygonId ) => {
  return api.get(`/image/${polygonId}`);
};

export const deleteImageEntry = ( imageId ) => {
  return api.delete(`/image/${imageId}`);
}

export const validateImageUrl = ( payload ) => {
  return api.post(`/validate-url`, {...payload});
};

export const postGroundTruthImage = (polygonId, payload) => {
  return api.post(
    URLS.groundTruthImages(polygonId),
    payload, 
    { headers: {'Content-Type': 'multipart/form-data'} }
  );
};