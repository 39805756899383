import { api } from "./api";

export const getUserMetadata = id => {
  return api.get(`/user/${id}/metadata`);
}

export const getUserRivers = config => {
  return api.get(`/user/rivers`, config);
}

export const getPolygonsByRiverId = (id, config) => {
  return api.get(`river/${id}/polygons`, config);
}