export default {
  currentUserId(context, id){
    localStorage.setItem('currentUserId', id)
    context.commit("setCurrentUserId", id);
  },
  
  eraseAdminSelectedUser(context){
    context.commit ("removeCurrentUser");
  },

  setAllUsers(context, users) {
    context.commit("setAllUsers", users);
  },

  selectRiverFromSidemenu(context, river){    
    context.commit("setSelectedRiver", river);
  },

  selectedRiverNameFromSidemenu(context, riverName){    
    context.commit("setSelectedRiverName", riverName)
  }
}