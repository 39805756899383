<template>
    <v-expansion-panels v-model="panel" >
        <v-expansion-panel
            v-for="polygon in polygons"
            :key="polygon.polygon_id"            
            v-model="panel"
            :value="0"
            expand
            @click="resetForm(classificationForm)"
        >             
            <v-expansion-panel-header class="primary--text">                      
                <!--polygon.name comes from polygon api call -- polygon.polygon_name comes from polygons-map api call-->
                <span class="text-color font-weight-black">{{ polygon.name || polygon.polygon_name }}</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>

                <v-carousel               
                    v-if="polygon.images.length > 0"
                    hide-delimiter-background
                    :show-arrows="polygon.images.length !== 1"
                    :height="screenWidth > 1030 ? '900px' : '1100px'"
                    light
                    @change="resetForm(classificationForm)"
                >
                    <v-carousel-item
                        v-for="image in polygon.images"
                        :key="image.image_id"                        
                    >              
                    
                        <!-- Card with Image and Form -->
                        <v-card                        
                            elevation="0"
                            :height="screenWidth > 1030 ? '750px' : '1400px'"
                            max-width="90%"
                            class="mx-auto"
                        >                        
                            <v-card-title>
                                
                                <div class="d-flex justify-space-between align-center mb-4">
                                    <v-radio-group                                   
                                        class="text-capitalize ma-0"
                                        v-model="colorBand"
                                        @click="imageUrl(image)"
                                        hide-details
                                        row
                                    >
                                        <v-radio
                                            label="False Color / (B11-B8-B4)"
                                            :value="true"
                                        ></v-radio>
                                        <v-radio                                        
                                            label="True Color Band /(B4-B3-B2)"
                                            :value="false"
                                        ></v-radio>                                        
                                    </v-radio-group>
                                </div>
                                <v-row class="align-center">
                                    <HydrotypeChart
                                        :polygonId="polygon.polygon_id"
                                        :riverId="riverid || polygon.river.id"
                                        :start-date="image.generated_at"
                                        :end-date="image.generated_at"
                                        :preselected-date-range="polygon.available_dates"
                                    />

                                    <DateFlowChart
                                        :polygonId="polygon.polygon_id"
                                    />
                                </v-row>
                            </v-card-title>

                         
                            <v-card-text>
                                <v-row class="justify-space-between">
                                    <v-col cols="5" lg="3">
                                        <v-form 
                                            :disabled = isEditOff
                                        >
                                            <v-row>
                                                <v-col
                                                >
                                                    <v-text-field
                                                        v-model="image.generated_at"
                                                        label="Date of Image"
                                                        readonly
                                                        outlined
                                                        hide-details
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>

                                            <!-- Hydrological Condition -->
                                            <v-row>
                                                <v-col>                                                        
                                                    <v-select
                                                        v-if="image.metadata"
                                                        outlined                                                       
                                                        v-model="image.metadata.hydrological_condition"
                                                        label="Hydrological condition *"
                                                        :items=waterLevels
                                                        item-title="text" 
                                                        color="primary"
                                                        hint="Classify the image as Flowing / Ponding / Dry / Cloudy"
                                                        persistent-hint
                                                    >                                                    
                                                        <template v-slot:item="{ item }">
                                                            <div class="d-flex align-center">
                                                                {{ item.text }} {{  item.description && '-' }} {{  item.description }}
                                                            </div>
                                                        </template>
                                                    </v-select>
                                                    <v-select
                                                        v-else
                                                        outlined                                                       
                                                        v-model="classificationForm.hydrological_condition"
                                                        label="Hydrological condition *"
                                                        :items=waterLevels
                                                        item-title="text" 
                                                        color="primary"
                                                        hint="Classify the image as Flowing / Ponding / Dry / Cloudy"
                                                        persistent-hint
                                                    >                                                    
                                                        <template v-slot:item="{ item }">
                                                            <div class="d-flex align-center">
                                                                {{ item.text }} {{  item.description && '-' }} {{  item.description }}
                                                            </div>
                                                        </template>
                                                    </v-select> 
                                                </v-col>
                                            </v-row>

                                            <!-- Notes -->
                                            <v-row class="mt-0">                                              
                                                <v-col>
                                                    <v-textarea     
                                                        v-if="image.metadata"
                                                        v-model="image.metadata.notes"
                                                        label="Notes"
                                                        outlined
                                                        variant="outlined"
                                                        hide-details
                                                    ></v-textarea>
                                                    <v-textarea     
                                                        v-else
                                                        v-model="classificationForm.notes"
                                                        label="Notes"
                                                        outlined
                                                        variant="outlined"
                                                        hide-details
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="hasPermissions(polygon.user.id)">
                                                <v-col cols="auto">  
                                                    <v-btn 
                                                        :loading="isButtonLoading"
                                                        :disabled="isEditOff"
                                                        @click="saveClassificationMetadata( polygon ,image )"
                                                        color="primary"
                                                        class="text-capitalize mr-4 mt-3"                                                    
                                                    >
                                                    Save
                                                    </v-btn> 
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn                                                         
                                                        @click="toggleEditMode"
                                                        :disabled="!isEditOff"
                                                        color="primary"
                                                        class="text-capitalize mt-3"   
                                                        text                                            
                                                    >
                                                        Edit
                                                    </v-btn>    
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="7" lg="9">
                                        <v-img
                                            aspect-ratio="1"
                                            :src="imageUrl(image)"
                                            :height="screenWidth < 770 ? '300px' : '500px' "
                                            contain
                                            :class="screenWidth < 770 ? 'small-img' : ''"
                                        >
                                            <template v-slot:placeholder>
                                                <v-row
                                                    class="fill-height ma-0"
                                                    align="center"
                                                    justify="center"
                                                >
                                                    <v-img
                                                        style="height: 500px;"
                                                        src="@/assets/loader.svg"
                                                        contain
                                                    ></v-img>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <MetadataInfo :info="info(polygon, image)"/> 
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-carousel-item>
                </v-carousel>

                <div v-else>
                    <p>There aren't any classified images for this polygon, yet.</p>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { withAsync } from               "@/helpers/withAsync";
import DateFlowChart from "@/components/metadataModal/DateFlowChart.vue";
import HydrotypeChart from "@/components/metadataModal/HydrotypeChart.vue";
import { CONFIG } from "@/common/config";
import { updateClassificationMetadata } from "@/api/polygonApi.js";
import { postMetadata } from "@/api/metadataApi.js"
import { mapGetters } from 'vuex';
import MetadataInfo from "@/components/metadataModal/MetadataInfo.vue";

export default {
    name: "ExpansionPanelPolygon",

    components: { DateFlowChart, HydrotypeChart, MetadataInfo },

    props: {
        screenWidth: {
            type: Number,
            required: false,
            default: window.innerWidth,
        },

        polygons: {
            required: false,
            type: Array,
        },

        riverid: {
            required: true,
            // type: Number, initial id is set to null and getting a console error            
        },

        open: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            classificationForm: {
                hydrological_condition: "",
                notes: "",
                metadata_id: null,
            },
            isEditOff: true,
            isButtonLoading: false,            
            colorBand: true,
            panel: null,
            waterLevels: [
               { text: "Flowing", description: "visible continuous flow of water along the reach"},
               { text: "Ponding", description: "discontinuous water presence, with isolated ponds along the reach" },
               { text: "Dry", description: "absence of surface water" },
               { text: "Cloudy"}
            ],
        };
    },

    computed: {
        ...mapGetters(["hasPermissions"])
    },

    methods: { 
        info(polygon, image) {
                return {
            user_name : polygon.user.name,
            river_name: polygon.river.name,
            created_at: image.created_at,
            polygon_name: polygon.name,
            polygon_notes: polygon.notes
                }
            },

        toggleEditMode() {
            this.isEditOff =!this.isEditOff;             
        },      

        async saveClassificationMetadata(polygon, image) {
            if(image.metadata) {
                this.updateClassificationMetadata(image);
            } else {
                this.postClassificationMetadata(polygon, image);
            }
            this.toggleEditMode();
        },

        async updateClassificationMetadata( image ) {
                //checking if has metadata so we use updateClassificationMetadata if doesnt we use the post method to set the metadata                
                    this.classificationForm.hydrological_condition = image.metadata.hydrological_condition;
                    this.classificationForm.notes = image.metadata.notes;
                    this.classificationForm.metadata_id = image.metadata.image_metadata_id;
                    this.isButtonLoading = true;
                    const {response, error} = await withAsync(updateClassificationMetadata, this.classificationForm);
                    if( error ) {
                        this.isButtonLoading = false;
                        return error.response.message;
                    }   
                    this.isButtonLoading= false; 
                        
        },

        async postClassificationMetadata( polygon, image ) {                
            const payload = {
                        hydrological_condition:  this.classificationForm.hydrological_condition,
                        notes: this.classificationForm.notes || undefined,
                        image_id: image.image_id,
                    };

            const {response, error} = await withAsync(postMetadata, payload);

            if( error ) {
                this.isButtonLoading = false;
                return error.response.message;
            }

            // update current image data in polygon and emit it to refeed the expansion panel with the updated data.
            const image_index = polygon.images.findIndex(img => img.image_id === image.image_id);
            polygon.images[image_index].metadata = {
                hydrological_condition:  this.classificationForm.hydrological_condition,
                notes: this.classificationForm.notes || undefined,
                image_metadata_id: image.image_id,
            }
            //emit event to parent componet to make a new api call by riverId to show the new data                               
            this.$emit('polygon-updated', polygon );
            this.$emit('metadataUpdated', this.riverid);
            this.isButtonLoading= false;
        },

        imageUrl(image) {
            const url = (u) => {
                return `${CONFIG.image_api}/${u}`;
            };

            if( !image.tci_url && !image.fci_url ) return null;

            if( image.tci_url && image.fci_url ) {
                return this.colorBand
                    ? url(image.fci_url)
                    : url(image.tci_url);
            }

            return image.tci_url
                ? url(image.tci_url)
                : url(image.fci_url);
        },  
        //function for setting classificationForm or general forms to null
        resetForm(obj) {
            Object.keys(obj).forEach(key => obj[key] = null);
            obj.notes = ''; 
        }         
    },

    mounted() {        
        if (this.open) {
            this.panel = 0;
        }
    },
};
</script>


<style scoped>
.btn {
    z-index: 9999;
}
.font-rubik {
    font-family: "Rubik", sans-serif;
}
.text-color {
    color: #19a7ce !important;
}
.height {
    height: 789px;
}
.z-index {
    z-index: 9999;
}
.small-device {
    height: 1150px;
}
.small-img {
    display: flex;
    margin-right: 50%;
}
>>> .v-dialog--active {
    display: flex !important;
    margin: 0 auto !important;
}
>>> .mdi-chevron-down::before,
>>> .mdi-chevron-down::after,
>>> .mdi-chevron-up::before,
>>> .mdi-chevron-up::after {
    color: #19a7ce !important;
}
>>> .mdi-chevron-right::before,
>>> .mdi-chevron-left::before {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #19a7ce !important;
}
>>> .mdi-circle::before {
    color: #19a7ce !important;
}
>>> .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
>>> .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
    border-color: white !important;
}

@media only screen and (max-width: 600px) {
    .small-img {
        width: 50%;
    }
}
</style>