<template>
    <!--  Gets the satellite image of the drawn/selected polygon for the selected date -->
    <v-row class="align-center justify-start get-satellite-img mb-4">
        <error-alert
            v-if="error"
            :error="error"
            :errorMessage="errorMessage"
            class="ml-15 error"
        ></error-alert>

        <v-radio-group
            class="ma-0"
            v-model="selectedImageType"
            :disabled="getSatelliteImageStatus_Pending || !dateOfImage"
            hide-details
            row
        >
            <v-radio
                label="True Color / (B4-B3-B2)"
                value="true_color"
            ></v-radio>
            <v-radio
                label="False Color / (B11-B8-B4)"
                value="false_color"
            ></v-radio>
        </v-radio-group>
        <v-btn
            color="primary"
            class="mx-2 white--text text-capitalize"
            :loading="getSatelliteImageStatus_Pending"
            :disabled="error || !!selectedImageUrl || !dateOfImage"
            @click="downloadImage"
        >
            Get satellite Image
        </v-btn>
        <v-btn
            v-if="showDiscard"
            color="red"
            class="ml-2 white--text text-capitalize"
            :loading="delSatelliteImageStatus_Pending"
            :disabled="error || !selectedImageUrl"
            @click="handleDelete"
        >
            Discard
        </v-btn>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { mapGetters } from "vuex";
import { setSelectedDate } from "@/api/polygonApi.js";
import { deleteImageEntry } from "@/api/metadataApi.js";
import { CONFIG } from "@/common/config";
import ErrorAlert from "@/components/ErrorAlert.vue";

export default {
    name: "GetSatelliteImg",

    components: { ErrorAlert },

    props: {
        dateOfImage: {
            type: String,
        },
    },

    data() {
        return {
            error: false,
            errorMessage: "",
            imageGroup: {
                true_color: null,
                false_color: null,
            },
            getSatelliteImageStatus: apiStatus.Idle,
            delSatelliteImageStatus: apiStatus.Idle,
            selectedImageType: "true_color",
        };
    },

    computed: {
        ...apiStatusComputed([
            "getSatelliteImageStatus",
            "delSatelliteImageStatus",
        ]),
        ...mapGetters(["getPolygonId"]),

        showDiscard() {
            return !Object.values(this.imageGroup).every((i) => i === null);
        },

        selectedImageUrl() {
            return this.selectedImage?.image_url
                ? CONFIG.image_api.concat(this.selectedImage.image_url)
                : null;
        },

        selectedImage: {
            get() {
                return this.imageGroup[this.selectedImageType];
            },
            set(newVal) {
                this.imageGroup[this.selectedImageType] = newVal;
            },
        },
    },

    methods: {
        // Api call to get the image url for the selected date
        async getSatelliteImage() {
            this.getSatelliteImageStatus = apiStatus.Pending;
            const payload = {
                image_date: this.dateOfImage
            };

            const { response, error } = await withAsync(
                setSelectedDate,
                this.getPolygonId,
                payload
            );

            if (error) {
                this.getSatelliteImageStatus = apiStatus.Error;
                this.error = true;
                this.errorMessage =
                    "The was an error while getting the satellite image.";
                return this.errorMessage;
            }

            this.getSatelliteImageStatus = apiStatus.Success;
            return response.data.data;
        },

        async downloadImage() {
            this.selectedImage = await this.getSatelliteImage();
        },

        async handleDelete() {
            await this.deleteImgEntry(this.selectedImage.image_id);
            if (this.delSatelliteImageStatus_Success) {
                this.imageGroup[this.selectedImageType] = null;
            }
        },

        async deleteImgEntry(image_id) {
            this.delSatelliteImageStatus = apiStatus.Pending;
            const { error } = await withAsync(deleteImageEntry, image_id);

            if (error) {
                this.delSatelliteImageStatus = apiStatus.Error;
                console.error(
                    "Error while deleting satellite image. Error: ",
                    error
                );
                this.errorMessage = error.response.data.message;
                return;
            }
            this.delSatelliteImageStatus = apiStatus.Success;
            return;
        },

        reset() {
            this.imageGroup = {
                true_color: null,
                false_color: null,
            };
        },
    },

    watch: {
        dateOfImage(newVal) {
            if (newVal) {
                this.reset();
            }
        },

        cancelProcedure(newVal) {
            if (newVal === true) {
                this.imageGroup = null;
            }
        },

        selectedImageUrl(newVal) {
            this.$emit("image-updated", newVal);
        },
    },
};
</script>

<style scoped>
.error {
    position: absolute;
    z-index: 99999;
    width: 350px;
}

.get-satellite-img {
    height: 50px;
    margin: 0;
}
</style>