<template>
    <v-card class="mr-4 justify-end switch-container">
        <v-switch
            class="polygon-switch-control font-weight-black text-capitalize font-rubik"
            v-model="showPolygons"
            color='primary'
            label="Show Polygons"
            :disabled="disabled"
            @change="toggleShowPolygons(showPolygons)"
            hide-details
            :loading="getAllPolygonsStatus_Pending"
        >
        </v-switch>
    </v-card>
</template>

<script>
// todo refactor this component entirely
import { withAsync } from "@/helpers/withAsync";
import { mapActions, mapGetters } from "vuex";
import { getPolygonOnMap } from "@/api/polygonApi.js";
import { debounce } from 'lodash';
import { apiStatus } from             "@/api/constants/apiStatus";
import { apiStatusComputed } from     "@/api/helpers/computedApiStatus";

const MY_POLYGON_COLOR = "rgba(255,255,0, 0.8)";
const OTHER_POLYGON_COLOR = "rgba(25, 167, 206, 0.8)";
const TOOLTIP = (polygon) => `
                <div>
                    <h3 style="margin-bottom: 0.4rem;">Polygon: ${polygon.name}</h3>
                    <p><b>River name:</b> ${polygon.river.name}</p>
                    <p><b>Polygon id:</b> ${polygon.polygon_id}</p>
                    <p><b>Date range:</b> ${polygon.available_dates[0]} - ${polygon.available_dates.slice(-1)}</p>
                    <p><b>Classified by:</b> ${polygon.user.name || "unknown"}</p>
                </div>`;

export default {
    name: "PolygonSwitchControl",

    props: {
        userZoom: {
            type: Number,
            required: true,
        },

        currentViewBounds: {
            type: Object,
            required: true,
        },

        map: {
            type: Object,
        },
        polygonShowZoomThreshold: {
            type: Number,
            required: true
        },
        isOnRemovalMode: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            showPolygons: this.$store.state.Polygons.showPolygons,
            polygonsMap: new Map(), // used to check already existing polygons & avoid polygon duplicates
            getAllPolygonsStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed("getAllPolygonsStatus"),
        ...mapGetters([
            "getShowPolygons",
            "getPolygonsOnBbox",
            "getCurrentUserData",
        ]),

        leafletPolygons() {
            return Array.from(
                this.polygonsMap.values(),
                (entry) => entry.leaflet
            );
        },

        fetchedPolygons() {
            return Array.from(
                this.polygonsMap.values(),
                (entry) => entry.fetched
            );
        },

        disabled() {
            return this.userZoom <= this.polygonShowZoomThreshold;
        },
    },

    methods: {
        ...mapActions(["setPolygonsOnBbox", "setShowPolygons"]),

        // Gets all polygon coords from all users that will be drawn on the map.
        async fetchPolygons(currentBounds) {
            this.getAllPolygonsStatus = apiStatus.Pending;
            if (!this.showPolygons){
             this.getAllPolygonsStatus = apiStatus.Success;
                return;
            }
            const boundingBox = {
                "data[_southWest][lat]": currentBounds._southWest.lat || 30,
                "data[_southWest][lng]": currentBounds._southWest.lng || -24,
                "data[_northEast][lat]": currentBounds._northEast.lat || 70,
                "data[_northEast][lng]": currentBounds._northEast.lng || 45,
            };

            const { response, error } = await withAsync(
                getPolygonOnMap,
                boundingBox
            );

            if (error) {
                this.getAllPolygonsStatus = apiStatus.Error;
                console.error(
                    "Error fetching coordinates for all polygons on bounding box: ",
                    error
                );
                return;
            }

            if (!response.data.data) {
                return;
            }
            this.getAllPolygonsStatus = apiStatus.Success;

            return response.data.data;
        },

        toggleShowPolygons(showPolygons) {
            // todo remove showPolygons from store
            this.showPolygons = showPolygons;

            if (!showPolygons) {
                this.map.getPanes().overlayPane.style = "display: none;";
                return;
            }

            this.updatePolygons(this.currentViewBounds);
            this.map.getPanes().overlayPane.style = "display: block;";
        },

        async updatePolygons(currentBounds) {
            const fetchedPolygons = await this.fetchPolygons(currentBounds);
            fetchedPolygons && this.addPolygonsToMap(fetchedPolygons);
        },

        // Function to add polygons to the map with the canRemove option
        addPolygonsToMap(fetchedPolygons) {
            const polygonColor = (currentUserId, polygonUserId) => {
                return currentUserId === polygonUserId ? MY_POLYGON_COLOR : OTHER_POLYGON_COLOR;
            };

            fetchedPolygons.forEach((polygon) => {
                const tooltip = {
                    template: TOOLTIP(polygon),
                    options: {
                        direction: "right",
                        permanent: false,
                        sticky: true,
                        offset: [10, 0],
                        opacity: 1,
                        className: "leaflet-tooltip-custom",
                    },
                };

                if (!this.polygonsMap.has(polygon.polygon_id)) {
                    const lPolygon = L.polygon(polygon.coordinates, {
                        color: polygonColor(polygon.user.id, this.getCurrentUserData.id),
                        canRemove: polygon.user.id === this.getCurrentUserData.id, // Set canRemove based on user
                    })
                        .bindTooltip(tooltip.template, tooltip.options)
                        .addTo(this.map);

                    this.polygonsMap.set(polygon.polygon_id, {
                        fetched: polygon,
                        leaflet: lPolygon,
                    });

                    lPolygon.on("click", (event) => {
                        this.$emit("polygon-clicked", { event, polygon });
                    });

                    lPolygon.on("mouseover", (event) => {
                        const tooltipWithError = `
                                    <p style='color: #9e2c45; margin-bottom: 8px; font-weight: bold; font-size: 14px;'>
                                        <span><i class='mdi mdi-close-circle' style="font-size: 18px; color:#9e2c45;"></i></span>
                                        You cannot delete other users' polygons
                                    </p>`
                                    .concat(tooltip.template)
                        if (this.isOnRemovalMode) {
                            if (!lPolygon.options.canRemove) {
                                lPolygon.off("click");
                                lPolygon.bindTooltip(tooltipWithError, {
                                    direction: 'north',
                                    sticky: true
                                })
                            }
                        } else {
                            lPolygon.on("click", (event) => {
                                this.$emit("polygon-clicked", { event, polygon });
                            });
                            lPolygon.bindTooltip(tooltip.template, tooltip.options)
                        }
                    });
                }
            });
        },
    },

    watch: {
        userZoom(newVal, oldVal) {
             if (newVal > this.polygonShowZoomThreshold && newVal > oldVal) {
                this.toggleShowPolygons(true);
            } else if (newVal <= this.polygonShowZoomThreshold) {
                this.toggleShowPolygons(false);
            }
        },

        currentViewBounds: debounce(function(val) {
            if (this.userZoom > this.polygonShowZoomThreshold) {
                this.updatePolygons(val);
            }
        }, 200),
        // async currentViewBounds(val) {
        //     if (this.userZoom > POLYGON_SHOW_ZOOM_THRESHOLD) {
        //         await this.updatePolygons(val);
        //     }
        // },
        // isOnRemovalMode(newVal, oldVal) {
        //     console.log('REMOVAL CHANGED', this.isOnRemovalMode);
        //     console.log('current view bounds', this.currentViewBounds);
        //     this.updatePolygons(this.currentViewBounds)
        // }
    },

    mounted() {
        this.showPolygons = this.getShowPolygons;
    },
};
</script>

<style lang="scss" scoped>
.switch-container {
    position: absolute;
    right: 0;
    top: 12px;
    padding: 0rem;
    z-index: 9999;
    .polygon-switch-control {
        padding: .75rem;
        margin: 0;
        z-index: 9000;
    }
}

.tooltip-icon {
    color: red;
    font-size: 18px;
}
</style>