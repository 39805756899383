import { api } from "./api";

const URLS = {
    polygonGraph: (polygonId) => `/polygon/${polygonId}/graph`,
};

export const deletePolygon = (polygonId) => {
    return api.delete(`/polygon/${polygonId}`);
};

export const sendCoordinates = (payload) => {
    return api.post(`/sentinel-image`, { ...payload });
};

export const getImage = (polygonId) => {
    return api.get(`/image/${polygonId}`);
};

export const setSelectedDate = (polygonId, payload) => {
    return api.post(`/polygon/${polygonId}/image`, { ...payload });
};

export const getUserPolygons = (id) => {
    return api.get(`/user/polygons?river_id=${id}`);
};

export const setDateRange = (payload) => {
    return api.post(`/polygon/validate-date-range`, { ...payload });
};
//get polygons by river id
export const getRiverPolygons = (id) => {
    return api.get(`river/${id}/polygons`);
};

export const getUnfinishedWork = (params) => {
    return api.get(`/user/unfinished-work`, { params });
};
/**
 *
 * @param {Number} itemNum how many rivers per page
 * @param {Number} pageNum the number of page as param
 * @returns rivers per page
 */
export const getAllRivers = (itemNum, pageNum, search) => {
    let baseUrl = "/rivers";
    let queryParams = [];

    // Append itemNum query parameter
    queryParams.push(`items=${itemNum}`);

    // Append page query params if  provided
    if (pageNum) {
        queryParams.push(`page=${pageNum}`);
    }

    if (search) {
        queryParams.push(`search=${encodeURIComponent(search)}`); // Use encodeURIComponent to encode special characters in the search term
    }

    // Construct the final URL
    let url = `${baseUrl}?${queryParams.join("&")}`;
    return api.get(url);
};

export const getAllPolygons = (config) => {
    return api.get(`/user/polygons`, config);
};

export const getPolygonGraph = (polygonId) => {
    return api.get(`${URLS.polygonGraph(polygonId)}`);
};

export const getHydrotypeChart = (payload) => {
    return api.post("/hydro-chart", payload);
};

export const getPolygonOnMap = (payload) => {
    const params = new URLSearchParams(payload);
    return api.get(`/polygon/polygons-map?${params}`);
};

export const updateClassificationMetadata = (payload) => {
    console.log(payload);

    return api.patch(`/metadata/${payload.metadata_id}`, { ...payload });
};
