import { render, staticRenderFns } from "./SatelliteImgPreview.vue?vue&type=template&id=108c6372&scoped=true"
import script from "./SatelliteImgPreview.vue?vue&type=script&lang=js"
export * from "./SatelliteImgPreview.vue?vue&type=script&lang=js"
import style0 from "./SatelliteImgPreview.vue?vue&type=style&index=0&id=108c6372&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108c6372",
  null
  
)

export default component.exports