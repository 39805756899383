<template>
  <div>
    <main-map 
      ref="map2"
      :latLng="latLng"
      :user-zoom="userZoom"
      @click-coords="clickedLocationCoords($event)"
      @updated-zoom="updateZoomValue($event)"
      @map-element="setCenterZoom($event)"
      @shape-coords="setShape($event)"
      @open-modal="openTheModal( $event )"
      @moved-on-map="currentViewBounds = $event"
      @toggle-removal-mode="isOnRemovalMode = $event"
    >
     
      <template #new-metadata-cards>
        <new-metadata-cards></new-metadata-cards>
      </template>

       <template #continue-previous-work>
        <ContinuePreviousWork />
      </template>
      
      <template #modal="slotProps">
        <SetMetadataCarousel
          ref="setMetadataCarousel"
          :polygon-coords="shape"
          :polygon-id="polygonId"
          :hasRiverInPolygon="slotProps.hasRiverInPolygon"
          :river-display-name="setMetadataLabel"
          @on-close-modal="handleSetMetadataCarouselClose(null)"
        />
      </template>   

      <template #polygon-switch-control="slotProps">
        <polygon-switch-control 
          ref="polygonSwitchControl"
          :user-zoom="userZoom" 
          :currentViewBounds="currentViewBounds" 
          :map="slotProps.map"
          :isOnRemovalMode = "isOnRemovalMode"
          :polygonShowZoomThreshold="slotProps.polygonShowZoomThreshold"
          @polygon-clicked="handlePolygonClicked"
          >
        </polygon-switch-control>
      </template>
    </main-map>

    <v-dialog
      v-model="showPolygonModal"
      max-width="1000"
    >
      <ExpansionPanelPolygon 
        open
        class="mx-auto" 
        :polygons="[polygonInExpansionPanel]"
        :riverid="riverid"
        @polygon-updated="polygonUpdateHandler"

      />
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { withAsync } from             "@/helpers/withAsync";
  import { deletePolygon } from "@/api/polygonApi.js";
  import MainMap from '@/components/MainMap.vue';
  import SetMetadataModal from '@/views/RiverTemp/SetMetadataModal.vue';
  import SetMetadataCarousel from '@/views/RiverTemp/SetMetadataCarousel.vue';
  import NewMetadataCards from '@/views/RiverTemp/NewMetadataCards.vue';
  import ContinuePreviousWork from '@/views/RiverTemp/ContinuePreviousWork.vue';
  import PolygonSwitchControl from '@/components/PolygonSwitchControl.vue';
  import ExpansionPanelPolygon from '@/views/RiverTemp/ExpansionPanelPolygon.vue';
import { debounce } from 'lodash';

  export default {
    name: "UserHome",

    components: {
      MainMap,
      SetMetadataModal,
      SetMetadataCarousel,
      NewMetadataCards,
      ContinuePreviousWork,
      PolygonSwitchControl,
      ExpansionPanelPolygon
    },

    data(){
      return{
        latLng: [53.0000, 9.0000],
        userZoom: 5,
        coordinates: [],
        map:"",
        polygonId: null,
        shape: null,
        currentViewBounds: {},
        showPolygonModal: false,
        setMetadataModalProps: {},
        polygonInExpansionPanel: null,
        isOnRemovalMode: false,
        riverid: null
      }
    },

    computed: {
      ...mapGetters(['getCurrentUserData']),

      setMetadataLabel() {
        return this.$store.state.Polygons.riverData.name ? `Classify river: ${this.$store.state.Polygons.riverData.name}` : "Classify river";
      }
    },
    
    methods:{

      /**
       * Updates expansion panel data & all polygon on map.
       * @param updatedPolygon - the polygon data object that was updated
       */
      polygonUpdateHandler(updatedPolygon){
          this.polygonInExpansionPanel = updatedPolygon;
          this.updatePolygonsOnMap();
      },

      clickedLocationCoords(event){
        this.latLng = new Array;
        this.latLng[0] = event.lat;
        this.latLng[1] = event.lng;
      },

      updateZoomValue(event){
        this.userZoom = event;
      },

      setCenterZoom(event){
        event.map.setCenter(this.latLng);
      },
      //todo this opens the modal when edit the polygon
      openPolygonModal(event) {
          this.showPolygonModal = true;
          this.polygonInExpansionPanel = event;
          this.riverid = event.river.id;
      },
      //todo this modal opens after creation of the polygon
      openTheModal( event ) {
        this.$refs.setMetadataCarousel.openModal();
      },

      setShape(event) {
        this.shape = event;
      },
      
      updatePolygonsOnMap () {        
        this.$refs.polygonSwitchControl.updatePolygons(this.currentViewBounds);
      },

      handlePolygonClicked({event, polygon}) {
        if (this.isOnRemovalMode) {
            // Delete polygon
            this.deletePolygon(event, polygon);
            return;
        }

        // open to view metadata
        this.openPolygonModal(polygon);
      },

      async deletePolygon(event, polygon) {
        if (polygon.user.id === this.getCurrentUserData.id) {
            await withAsync( deletePolygon, polygon.polygon_id );
        }
        // event.originalEvent.stopPropagation();
        // event.originalEvent.stopImmediatePropagation();
        // event.originalEvent.preventDefault();
        // event.sourceTarget._map.pm._globalRemovalModeEnabled = false;
      },

      handleSetMetadataCarouselClose (event) {
        this.updatePolygonsOnMap();
        this.setShape(event);
      }
    },
  };
</script>

