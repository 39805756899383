<template>
  <v-alert
    v-model="errorState"
    :prominent="prominent"
    shaped
    dismissible
    elevation="2"
    type="error"
    color="error"
    class="error-alert"
    transition="slide-x-reverse-transition"
    @input="emitChange($event)"
  >
    {{ errorMessage }}
  </v-alert>
</template>

<script>
export default {
  name: "ErrorAlert",

  props: {

    error: {
      type: Boolean,
      required: true,
    },

    errorMessage:{
      type: String,
      required: true
    },

    prominent: {
      type: Boolean,
      default: true,
    },
  },

    data(){
        return{
            errorState: false,
        }
    },

  beforeMount() {
    this.$emit("error-status", this.error);
  },

  methods:{

    emitChange(event){
      this.$emit("error-closed", event);
    },
    
    toggleTest() {
    },
  },

  watch:{
    error(newVal){
        this.errorState = newVal;
    },
  }
};
</script>

<style scoped>
  .error-alert {
    position: absolute;
    z-index: 9999;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    margin-top: 1rem;
  }
</style>
