import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "RiverTemp",
        component: () =>
            import(/* webpackChunkName: "RiverTemp" */ "@/views/RiverTemp"),
        meta: {
            requiresAuth: true, // todo fix routing - user role
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "Login" */ "@/views/Auth/LogUser"),
        meta: {
            requiresAuth: false,
        },
    },
    // todo disable reset & singup for first release
    {
        path: "/forgot-password",
        name: "ForgottenPassword",
        component: () =>
            import(
                /* webpackChunkName: "ForgottenPassword" */ "@/views/Auth/ForgottenPassword"
            ),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () =>
            import(
                /* webpackChunkName: "ResetPassword" */ "@/views/Auth/ResetPassword"
            ),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/signup",
        name: "SignUp",
        component: () =>
            import(/* webpackChunkName: "SignUp" */ "@/views/Auth/SignUser"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "",
        component: () =>
            import(/* webpackChunkName: "AdminPage" */ "@/views/Admin/SideBar"),
        children: [
            {
                path: "/admin-panel",
                name: "AdminPage",
                components: {
                    default: () =>
                        import(
                            /* webpackChunkName: "AdminPage" */ "@/views/AdminPage"
                        ),
                },
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: "*",
        name: "PageNotFound",
        component: () =>
            import(
                /* webpackChunkName: "PageNotFound" */ "@/views/PageNotFound"
            ),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isAuthenticated) {
            next({ name: "Login" });
        } else {
            next(); // go to wherever I'm going
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
});

router.afterEach((to, from) => {
    if (to.name === "RiverTemp" && from.name === "AdminPage") {
        store.dispatch("selectedRiverNameFromSidemenu", ""); //reset name
        store.dispatch("selectRiverFromSidemenu", ""); //reset id
    }
});

export default router;
