import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  state() {
    return {
      dates: [],
      savedDates: [],
      polygonId: localStorage.getItem('polygonId') || "",
      // todo add polygon related data together + isSaved?
      // polygonData: {
      //   dates: [],
      //   savedDates: [],
      //   polygonId: "",
      // },
      polygonsOnBbox: [],
      showPolygons: false,
      riverData: {
        name: "",
        customName: "",
        showCustomName: false,
        river_id: ""  
      },
      hasRiverInPolygon: false,
      shapeCoords: null
   };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
