<template>
    <div>
        <v-btn
            color="primary"
            class="text-capitalize"
            @click.stop="openChartDialog"
            text
        >
            Flow Chart
            <v-icon
                color="primary"
                class="ml-2"
            >
                mdi-chart-line
            </v-icon>
        </v-btn>
        <v-row>
            <v-col>
                <ErrorAlert
                    :error="error"
                    :error-message="errorMessage"
                    @error-closed="error=false"
                />
            </v-col>
        </v-row>

        <v-dialog
            v-model="chartDialog"
            class="pa-4"
            width="auto"
        >
            <v-card class="pa-8">
                <apexchart
                    ref="chart"
                    width="900"
                    :options="options"
                    :series="series"
                ></apexchart>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import { getPolygonGraph } from "@/api/polygonApi.js";
import { withAsync } from "@/helpers/withAsync";
import ErrorAlert from "@/components/ErrorAlert.vue";

export default {
    name: "DateFlowChart",

    components: { ErrorAlert },

    props: {
        polygonId: {
            type: Number,
        }
    },
    data() {
        return {
            error: false,
            errorMessage: "",
            chartDialog: false,
            // Chart data
            series: [],
            options: {
                chart: {
                    type: "line",
                    height: 350,
                },
                stroke: {
                    curve: "stepline",
                    width: 2,
                },

                xaxis: {
                    type: "datetime",
                    labels: {
                        formatter: function (timestamp) {
                            // return new Date(timestamp).toLocaleString('en-GB', { timeZone: 'UTC' }); // The formatter function overrides format property
                            const date = new Date(timestamp);
                            const year = date.getFullYear();
                            const month = String(date.getMonth() + 1).padStart(2, "0");
                            const day = String(date.getDate()).padStart(2, "0");
                            return `${day}-${month}-${year}`;
                        }, 
                    }
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            const value = Math.floor(val);
                            const dataMapping = {
                                0: 'Still not classified',
                                1: 'Dry',
                                2: 'Ponding',
                                3: 'Flowing'
                            }
                            return dataMapping[value];
                        },
                        style: {
                            fontSize: '16px'
                       
                        },
                    },
                    // showDuplicates: false,
                    tickAmount: 3,
                    min: 0,
                    max: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                title: {
                    text: "Date - Flow Condition chart",
                    align: "left",
                },
                markers: {
                    size: 10,
                    hover: {
                        sizeOffset: 4,
                    },
                },
                fill: {
                    opacity: 0.9,
                    type: "solid",
                },
                legend: {
                    fontSize: 18,
                    labels: {
                    colors: ['#2E8B57', 'grey'],
                    useSeriesColors: false
                    },
                    markers: {
                        fillColors: ['#2E8B57', 'grey'],
                    },
                },
                tooltip: {
                    marker: {
                        show: false,
                    },
                },
                colors: ["dimGrey", "#F8C30000"],
            }
        }
    },

    methods: {
        async openChartDialog() {
            this.series = [];
            this.chartDialog = true;
            const response = await this.getPolygonGraph();
            const { graph, polygon_id, polygon_name, river_name } = response;
            this.updateChart(graph);
        },

        async getPolygonGraph() {
            const { response, error } = await withAsync(
                getPolygonGraph,
                this.polygonId
            );

            if (error) {
                console.error("Error: ", error);
                this.errorMessage = error.response.data.message;
                this.error = true;
                return this.errorMessage;
            }

            return response.data.data;
        },

        transformData(inputData) {
            const transformedData = {
                classified: [],
                notClassified: []
            };

            // Mapping of data with number value and color
            const categoryMapping = {
                "Flowing": { value: 3, color: "#6495ED", },
                "Ponding": { value: 2, color: "#FF7F50" },
                "Dry": { value: 1, color: "#DC143C" },
                "Cloudy": { value: 0, color: "grey" },
                null: { value: 0, color: "grey" }
            }

            for(const key in inputData) {
                const flow = inputData[key]; // current inputData value: eg: 'Flowing'
                const value = categoryMapping[flow].value; // the number mapping to flow
                const color = categoryMapping[flow].color;
                const entry = {
                    x: key,
                    y: value,
                    fillColor: color
                }

                // Separate classified data from not in different arrays
                if (inputData[key] === "Cloudy" || !!(!inputData[key])) {
                    transformedData.notClassified.push(entry)
                } else {
                    transformedData.classified.push(entry)
                }
            }


            // sort the data arrays in order to be displayed correctly in chart (important step)
            for (const key in transformedData) {
                // sort by their date in descending order
                transformedData[key].sort((a, b) => new Date(b.x).getTime() - new Date(a.x).getTime());
            }
            return transformedData;
        },

        updateChart(graph) {
            const transformData = this.transformData(graph);
            this.series.push({
                name: "Flow Status",
                data: transformData.classified
            });

            this.series.push({
                name: "Not Classified",
                data: transformData.notClassified
            });
        },
    },

    created() {
        this.userId = this.$route.params.userId;
    },

    async mounted() {},
};
</script>