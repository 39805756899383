import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  state() {
    return {
      currentUserId: "",
      allUsers: [],
      allRiverPolygons: [],
      selectedRiver: "",
      selectedRiverName:""
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
