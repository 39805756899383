<template>
    <v-row class="">
        <!-- Heading -->
        <h3 class="text-h5">
            Other Metadata
        </h3>

        <div class="d-flex flex-row justify-start flex-wrap mt-2 metadata-container">
            <div class="d-flex flex-column info-item">
                <label class="label">
                    User:
                </label>
                <span class="grey--text">
                    {{ info.user_name }}
                </span>
            </div>

            <div class="d-flex flex-column info-item">
                <label class="label">
                    River
                </label>
                <span class="grey--text">
                    {{ info.river_name ? info.river_name : '-' }}
                </span>
            </div>

            <div class="d-flex flex-column info-item">
                <label class="label">
                    Sat. Download Date
                </label>
                <span class="grey--text">
                    {{ info.created_at ? info.created_at : '-' }}
                </span>
            </div>

            <div class="d-flex flex-column info-item">
                <label class="label">
                    Polygon Name
                </label>
                <span class="grey--text">
                    {{ info.polygon_name ? info.polygon_name : '-'}}
                </span>
            </div>

            <div class="d-flex flex-column info-item">
                <label class="label">
                    Polygon Notes
                </label>
                <span class="grey--text metadata-notes" :title="info.polygon_notes ? info.polygon_notes : ''">
                    {{ info.polygon_notes ? info.polygon_notes : '-'}}
                </span>
            </div>
        </div>
    </v-row>
</template>

<script>
export default {
    name: "MetadataInfo",

    props: {
        info: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            requiredMessage: "This field is required",
        };
    },
};
</script>

<style scoped>
    .label {
        font-weight: 600;
        color: rgb(55, 55, 55);
    }
    .metadata-container {
        column-gap: 1rem;
        row-gap: 1rem;
    }

    .metadata-notes {
        max-width: 100px;
        white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
    }

    .info-item {
        width: 150px; /* Set a fixed width for each flex item */
    }

    /* .info-item {
        display: grid;
        grid-template-columns: 250px auto;
    } */
</style>