export default {
    hasAvailableDates(state) {
        return !!state.dates;
    },

    getPolygonId(state) {
        return state.polygonId;
    },

    getRiverName(state) {
        return state.riverData.name;
    },

    getRiverId(state) {
        return state.riverData.river_id;
    },

    getRiverCustomName(state) {
        return state.riverData.customName;
    },

    getShowCustomName(state) {
        return state.riverData.showCustomName;
    },

    getHasRiverInPolygon(state) {
        return state.hasRiverInPolygon;
    },

    getAvailableDates(state) {
        return state.dates.sort((a, b) => new Date(a) - new Date(b));
    },

    getShapeCoords(state) {
        return state.shapeCoords;
    },

    getSavedDates(state) {
        return state.savedDates;
    },

    getPolygonsOnBbox(state) {
        return state.polygonsOnBbox;
    },

    getShowPolygons(state) {
        return state.showPolygons;
    },

    areAllDatesSaved(state) {
        const a = state.dates;
        const b = state.savedDates;
        return !!(a.length === b.length && a.every((item) => b.includes(item)));
    },
};
