export default {
  setAvailableDates(state, dates){
    state.dates = [];
    state.dates = dates;
  },

  setRiverName(state, name) {
    state.riverData.name = name;
    localStorage.setItem('riverData', JSON.stringify(state.riverData));
  },
  
  updateShapeCoords(state, coordinates) {
    state.shapeCoords = coordinates;
  },

  updateSavedDates(state, savedDates) {
    state.savedDates = savedDates;
  },

  setPolygonId(state, polygonId){
    state.polygonId = polygonId;
    localStorage.setItem("polygonId", polygonId);
  },

  setRiverName(state, name) {
    state.riverData.name = name;
    localStorage.setItem('riverData', JSON.stringify(state.riverData));
  },

  setRiverId(state, river_id) {
    state.riverData.river_id = river_id;
    localStorage.setItem('riverData', JSON.stringify(state.riverData));
  },

  setRiverCustomName(state, customName) {    
    state.riverData.customName = customName;
    localStorage.setItem('riverData', JSON.stringify(state.riverData));
  },

  setShowCustomName(state, showCustomName) {
    state.riverData.showCustomName = showCustomName;
    localStorage.setItem('riverData', JSON.stringify(state.riverData));
  },

  setHasRiverInPolygon(state, hasRiverInPolygon) {
    state.hasRiverInPolygon = hasRiverInPolygon;
    localStorage.setItem("hasRiverInPolygon", state.hasRiverInPolygon)
  },

  setPolygonsOnBbox(state, polygonsOnBbox) { 
    state.polygonsOnBbox = polygonsOnBbox;  
    localStorage.setItem("polygonsOnBbox", JSON.stringify(polygonsOnBbox));
  },

  setShowPolygons(state, showPolygons) {
    state.showPolygons = showPolygons;
    localStorage.setItem("showPolygons", showPolygons);
  },

  resetRiverData( state ) {
    state.riverData = {
      name: "",
      customName: "",
      showCustomName: false,
      river_id: ""
    }
    state.savedDates = [];
    localStorage.removeItem('riverData')
    localStorage.removeItem("hasRiverInPolygon");
  }
}