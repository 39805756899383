import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify, {
    customPorperties: true,
});

const opts = {}

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#19A7CE",
            grey: "#606060",
            userPolygon: "#FFD207",
            otherPolygon: "#19A7CE",
            
            // secondary: '#424242',
            // accent: '#82B1FF',
            // error: '#FF5252',
            // info: '#2196F3',
            // success: '#4CAF50',
            // warning: '#FFC107',
          },
        },
      },
});
