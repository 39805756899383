<template>
  <div 
    id="nav" 
    class="nav"
  >
    <div>
      <router-link to="/" class="brand">
        <img 
          src="@/assets/logoHorTransp.png"
          :width="screenWidth >= 850 ? '30%' : '100%'"  
        />
      </router-link>
      
    </div>
    <nav v-if="screenWidth >= 850">
      <label
        class="mr-5 text"
      >
        {{  $store.state.Auth.user  ? "User: " + $store.state.Auth.user.name  : "User: " + userName }}
      </label>
      <router-link to="/admin-panel" class="mr-5 nav-item">
        <button>Home</button>
      </router-link>
      <router-link :to="{name:'Login'}" class="mr-5 nav-item">
        <button @click="logout">Logout</button>
        
      </router-link>
    </nav>
    <nav v-else>
      <router-link 
        :to="{name:'Login'}"   
        class="mr-5 nav-item"
      >
      <v-btn
        color="transparent"
        elevation="0"
        @click="logout()"
      >
        <v-icon color="primary">
          mdi-power
        </v-icon>
      </v-btn>
      </router-link>
    </nav>
  </div>
</template>

<script>
  import { mapGetters } from            "vuex";
  import { withAsync } from             "@/helpers/withAsync";
  import { apiStatus } from             "@/api/constants/apiStatus";
  import { apiStatusComputed } from     "@/api/helpers/computedApiStatus";
  import { logoutUser } from            "@/api/authenticationApi.js"  
  import { apiObject } from             "@/api/api";

  export default {
    name: "MainMenu",

    data(){
      return{
        userName: "",
        admin: null,
        screenWidth: null,
        logoutUser: apiStatus.Idle
      }
    },

    computed:{

      ...apiStatusComputed("logoutUser"),
    },

    methods:{

      handleResize() {
        this.screenWidth = window.innerWidth;
      },

      async logout(){
        this.logoutUser =               apiStatus.Pending;

        const {response, error} =       await withAsync(logoutUser);
        if(error){              
            this.logoutUser =           apiStatus.Error;
          }

        this.$store.dispatch("logout");

        this.logoutUser =               apiStatus.Success;
        localStorage.removeItem("isResearcher");
      },

      goToAdminPanel() {
        this.$router.push('/admin-panel')
      }
    },

    created(){
      this.userName = localStorage.getItem('userName');
      this.screenWidth = window.innerWidth;

      window.addEventListener("resize", this.handleResize);
    },

    updated() {
      this.userName = localStorage.getItem('userName');
      this.screenWidth = window.innerWidth;
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
  }
</script>

<style scoped>

  .brand{
    text-decoration: none;
  }
  .river{
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 50px;
    background-color: #19A7CE;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding-left: 25%;
    padding-right: 1%;
  }
  .temp{
    font-family: 'Rubik', sans-serif;
    font-weight: 700;
    font-size: 50px;
    color: #19A7CE;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .nav {
    font-family: 'Rubik', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }
  .nav > .brand {
    height:100%;
    display: flex;
  }
  .nav .nav-item {
    box-sizing: border-box;
    margin: 0 5px;
    color: #19A7CE;
    font-weight: 600;
    font-size: 20px;
  }
  .nav .nav-item:hover{
    color:#1c7d98 !important;
  }
  .nav .nav-item.router-link-exact-active {
    color:#19A7CE;
  }
  .text{
    color: #19A7CE;
    font-weight: 600;
    font-size: 20px;
  }
</style>