import { api } from "./api";

const URLS = {
    newUser: "/user",
    login: "/login",
    logout: "/logout",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
    verifyUser: "/verify-email",
    authenticatedUser: "/auth/user",
};

export const createUser = payload => {
    return api.post(`${URLS.newUser}`, {...payload});
};

export const loginUser = payload => {
    return api.post(`${URLS.login}`, {...payload});
}

export const logoutUser = () => {
    api.delete(`${URLS.logout}`)
    return delete apiObject.defaults.headers.common["Authorization"];
}

export const forgottenPassword = payload => {
    return api.post(`${URLS.forgotPassword}`, {...payload});
}

export const getAuthUser = () => {
    return api.get(`${URLS.authenticatedUser}`);
}

export const saveResetPassword = (payload) => {
    return api.post(`${URLS.resetPassword}`, {...payload});
}

export const verifyUserEmail = (id, token, config) => {
    return api.get(`${URLS.verifyUser}/${id}/${token}`, config);
}