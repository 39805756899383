<template>
    <v-row class="align-center justify-center my-auto container">
        <v-col cols="1" class="pa-0 align-center justify-center d-flex">
        <v-btn
            fab
            small
            elevation="2"
            color="primary"
            class="white--text text-capitalize"
            append-icon="mdi-chevron-right"
            :disabled="disablePrevious"
            @click="$emit('load-previous-image'); key--"
        >   
            <v-icon>
                mdi-chevron-left
            </v-icon>
        </v-btn>
    </v-col>
    <v-col cols="10">
        <v-img
            v-if="isLoading"
            max-height="500px"
            transition="fade-transition"
            src="@/assets/loader.svg"
            contain
        />
        <v-img
            v-else
            content-class="animation"
            transition="fade-transition"
            :key="key"
            max-height="500px"
            :src="imgSrc || require('@/assets/image-placeholder.svg')"
            contain
        />
    </v-col>
    <v-col class="pa-0 align-center justify-center d-flex" cols="1">
        <v-btn
            fab
            small
            elevation="2"
            color="primary"
            class="white--text text-capitalize"
            append-icon="mdi-chevron-right"
            :disabled="disableNext"
            @click="$emit('load-next-image'); key++"
        >
            <v-icon>
                mdi-chevron-right
            </v-icon>
        </v-btn>
    </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "SatelliteImgPreview",
        emits: ["load-previous-image","load-next-image"],

        props: {
            isLoading: {
                required: true,
                default: false
            },
            imgSrc: {
                required: true,
                default: null
            },
            disablePrevious: {
                type: Boolean,
                required: true,
                default: false,
            },
            disableNext: {
                type: Boolean,
                required: true,
                default: false,
            }
        },

        data() {
            return {
                key: 0,
            }
        }
    };
</script>

<style lang="scss" scoped>
    .container {
        height: 100% !important;
    }
</style>