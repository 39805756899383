<template>
    <div>
        <MetadataForm
            ref="form"
            :preSelectedDate="selectedDates[0]"
            :dateList="selectedDates"
            :preLoadedPolygonData="selectedPolygon"
            :polygonId="polygonId"
            @date-changed="currentDate = $event"
        />
    </div>
</template>

<script>
    import MetadataForm from "@/components/metadataModal/MetadataForm.vue";
    import MetadataInfo from "@/components/metadataModal/MetadataInfo";

    export default {
        name: "SetMetadataModal",

        components: {
            MetadataForm,
            MetadataInfo,
        },

        props: {

            polygonId: {
                type: Number,
                required: true
            },

            selectedDates: {
                type: Array,
                required: true,
                default: []
            },

            selectedPolygon: {
                type: Object,
                required: true,
                default: {}
            }
        },

        // todo the same component as setMetadata modal: refactor
        created() {
            this.dateList = this.selectedDates;
            this.preSelectedDate = this.dateList[0];
        },

        computed: {
            // todo may not be needed
            other_metadata() {
                return {
                    user_name: this.selectedPolygon.user.name,
                    river_name: this.selectedPolygon.river.name,
                    created_at: this.selectedPolygon.images[0].created_at,
                    polygon_name: this.selectedPolygon.name,
                    polygon_notes: this.selectedPolygon.notes
                }
            }
        },

        data() {
            return {
                preSelectedDate: "",
                currentDate: null,
                dateList: []
            };
        },
    };
</script>