export default {
  setCurrentUserId(state, id){
    state.currentUserId = id;
  },
  
  removeCurrentUser(state){
    state.currentUserId = "";
  },

  setAllUsers(state, users){
    state.allUsers = users;
  },

  setAllRiverPolygons( state, riverPolygons ) {
    state.setAllRiverPolygons = riverPolygons;
  },

  setSelectedRiver(state, river){
    state.selectedRiver = river;
  },

  setSelectedRiverName(state, riverName) {    
    state.selectedRiverName = riverName;
  }
}