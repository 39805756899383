<template>
  <div>
    <!-- Base map -->
    <l-map 
      ref="map"
      style="height: calc(100vh - 100px)"
      :min-zoom="5"
      :zoom="zoom"
      :options="mapOptions"
      :center="center"
      class="main-map"
      
      @update:center="shape !== null ? changeCenter() : ''"
      @moveend="emitMovedOnMap($event)"
      @update:zoom="updateZoom($event)"
      @click="getCoordinates($event)"
      @ready="setMap()"
    >
      <l-tile-layer
        :url="url"
      ></l-tile-layer>

      <l-wms-tile-layer
        :visible="showRivers"
        key="draxis"
        :base-url="layerURL"
        layers="WFD_grouped"
        exceptions="application/vnd.ogc.se_inimage"
        name="draxis"
        :transparent="true"
        :options="{ tiled: true }"
        format="image/png"
        layer-type="base"
      ></l-wms-tile-layer>

      <l-control
        :position="'bottomright'"
        class="map-legend"
      >
        <span><v-icon small color="userPolygon">mdi-circle</v-icon> User ROIs</span>
        <span><v-icon small color="otherPolygon">mdi-circle</v-icon> Other ROIs</span>
      </l-control>

      <!-- Slot for the Continue Previous Work button -->
      <slot name="continue-previous-work"></slot>

      <!-- Slot for the See Registered Metadata button -->
      <slot name="new-metadata-cards"></slot>

            <!-- Slot for the 'Set Metadata' button -->
      <slot
          name="modal"
          :hasRiverInPolygon="getHasRiverInPolygon"
      ></slot>

      <!-- Slot for showing the view of all user polygons -->
      <slot
        name="polygon-switch-control"
        :map="map"
        :polygonShowZoomThreshold="polygonShowZoomThreshold"
      ></slot>
    </l-map>
  </div>
</template>

<script>
  import L from 'leaflet'
  import { LMap, LTileLayer, LImageOverlay, LWMSTileLayer, LControl } from 'vue2-leaflet';
  import axios from 'axios';
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import CONFIG from "@/common/config";

  
  export default {
    name: "MainMap",

    props: {

      latLng: {
        type: Array,
        required: true
      },

      userZoom: {
        type: Number,
        required: true
      },
    },

    components: {
      LMap,
      LTileLayer,
      LImageOverlay,
      LControl,
      'l-wms-tile-layer': LWMSTileLayer
    },

    data() {
      return {
        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        mapOptions: {
            zoomSnap: 0.01,
            wheelDebounceTime: 10,
            attributionControl: false,
        },
        center: [47.0000, 10.000],
        zoom: 5,
        drawNew: false,
        map: null,
        shape: null,
        shapeCoordinates: null,
        bounds: null,
        layerInfo:null,
        wmsLayer: null,
        newBounds: null,
        riverName: null,
        layerURL: CONFIG.wms_layer_url,
        isOnRemovalMode: false,
        showRivers: false,
        polygonShowZoomThreshold: 7,
      };
    },

    computed: {
      ...mapGetters([
      'getHasRiverInPolygon',
      'getPolygonsOnBbox',
      'getShowPolygons',
      'isAdmin',
      ]),
    },

    methods: {
      ...mapMutations([
        'setHasRiverInPolygon',
        'setShowCustomName',
        'resetRiverData'
      ]),

      ...mapActions([
        'setAvailableDates',
        'setRiverShapeCoordinates',
        'setRiverData',
        'setShowPolygons',
      ]),

      setMap() {
        this.map = this.$refs?.map?.mapObject;

        if (this.zoom === 5){
            this.setMapBounds();
        }
        this.map.doubleClickZoom.disable();

        this.addMapControls();

        this.map?.pm?.removeControls();

        this.handleShapeCreation();

        this.handleShapeRemoval();

        // Handle toggling of removal mode
        this.map.on('pm:globalremovalmodetoggled', (e) => {
            this.isOnRemovalMode = e.enabled;
            this.$emit("toggle-removal-mode", this.isOnRemovalMode)
        });

        this.map.pm.setLang('renameTooltips', this.changeControlsHoverName(), 'en');

      },

      //change hover name of map controls
      changeControlsHoverName() {
        return {        
          buttonTitles: {
            drawPolyButton: 'Draw ROI - Region Of Interest',
            deleteButton: 'Remove ROI'
          }
        }
      },

      // Emit drawn shape to other components
      handleShapeCreation(){
        this.map.on('pm:create', (event) => {
          this.shape = event.layer;

          // Add click listener to open set metadata
          this.shape.on('click', () => {
            // Prevent set metadata when clicking on polygon for deletion.
            if (!this.isOnRemovalMode) {
                this.$emit("open-modal", event)
            }
          });

          this.$emit("shape-coords", this.shape.getLatLngs())
          this.queryOSMRiverName();
        });
      },

    // Emit shape-erase event to other components and remove it from localStorage
    handleShapeRemoval() {
    // Detect when the removal mode is toggled
    this.map.on('pm:globalremovalmodetoggled', (event) => {
            // Attach a custom click event to polygons
            this.map.eachLayer((layer) => {
                if (layer instanceof L.Polygon && layer.options.canRemove === false) {
                    layer.on('pm:remove', (e) => {
                        this.shape = e.layer;

                        // Re-add the layer to the map if it was removed
                        this.map.addLayer(layer);

                        // Notify the user
                    });
                }
            });
            if (!event.enabled) {                {
                // Detach the custom click event when removal mode is disabled
                this.map.eachLayer((layer) => {
                    if (layer instanceof L.Polygon && layer.options.canRemove === false) {
                        layer.off('pm:remove');
                    }
                });
            }   
        }
    });
},
      // Drawing and erasing tools
      addMapControls(){
        this.map.pm.addControls({
          position: 'topleft',
          drawCircle: false,
          drawText: false,
          drawControls: !this.isAdmin,
          cutPolygon: false, //hide from ui with false was !this.isAdmin
          drawMarker: false,
          drawPolygon: !this.isAdmin,
          drawRectangle: false,
          drawPolyline: false,
          drawCircleMarker: false,
          editPolygon: false, //hide from ui with false was true
          dragMode: false,
          rotateMode: false,
          removalMode: true
        });
      },

      changeCenter(){
        const coords = this.shape.getLatLngs();
        const bounds = L.latLngBounds(coords); 
        const center = bounds.getCenter(); 

        this.center = [center.lat, center.lng]; 
        this.center = [...this.center];
        this.map.setMaxBounds(null);
      },

      // Prevent map zoom on double click
      handleMapDoubleClick(event) {
        event.originalEvent.preventDefault();
      },

      updateZoom(event) {
        this.$emit("updated-zoom", event);
      },

      getCoordinates(event) {
        //this.$emit("click-coords", event.latlng);
      },

      emitMovedOnMap(event) {
        this.$emit("moved-on-map", event.target.getBounds());
      },

      checkMapBoundsInKm() {
      const bounds = this.map.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Calculate the distance in meters
      const distanceLat = this.map.distance([ne.lat, sw.lng], [sw.lat, sw.lng]);
      const distanceLng = this.map.distance([ne.lat, ne.lng], [ne.lat, sw.lng]);

      // Convert meters to kilometers
      const distanceKmLat = distanceLat / 1000;
      const distanceKmLng = distanceLng / 1000;
      if (distanceKmLat <= 200 || distanceKmLng <= 200) {   
        this.addMapControls();
        this.showRivers=true;
      } else {        
        this.showRivers=false;
        this.map.pm.removeControls();
      }
    },

      setMapBounds() {
        const southWest = L.latLng(30.0000, -24.0000); 
        const northEast = L.latLng(70.0000, 45.0000); 
        this.newBounds = L.latLngBounds(southWest, northEast);

        this.map.setMaxBounds(this.newBounds);
      },

      // Api call, to external api, to retrieve the name of the river contained in the drawn shape
      async queryOSMRiverName() {
        const bFormatArray = this.shape.getLatLngs().flatMap(points => points.map(point => `${point.lat} ${point.lng}`)).join(" ");

        this.setRiverShapeCoordinates( bFormatArray );
        //this.$store.dispatch("updateShapeCoords", bFormatArray);

        const query = `
          [out:json];
          way["waterway"="river"]["name"](poly:"${bFormatArray}");
          out tags;
        `;

        try {
          const response = await axios.post(CONFIG.river_names_api, query);
          const data = response.data;

          if( !"elements" in data ) return;
           
          this.$store.commit('setHasRiverInPolygon', true);

          for (const element of data.elements) {
            let riverId = null;
            if ("tags" in element && "name" in element.tags) {
              this.riverName = element.tags.name;
              riverId = element.id;
              if( "name:en" in element.tags){
                this.riverName = element.tags["name:en"];              
              }else{
                this.riverName = element.tags.name;
              };

              let rData = {
                river_id: riverId,
                river_name: this.riverName  
              };
              
              this.setRiverData( rData );

              return this.riverName;
            }
        
          }
         
        } catch (error) {
          console.error(error);
        }
        return null;
      },
      
      async handleRiverNotFound() {        
        // let user continue and set Metadata and put an extra step to declare the new river's name.
        this.$store.commit("setShowCustomName", true);
        this.$store.commit('setHasRiverInPolygon', true);
      },

      getBoundingBoxStringFromShape(shape) { 
        const northEast = Object.values(shape._bounds._northEast).reverse().join(",");
        const southEast = Object.values(shape._bounds._southWest).reverse().join(',');
        const bboxString = northEast.concat(",",southEast);
        return bboxString;
      }
    },
    
    destroyed() {
        this.$store.dispatch('setShowPolygons', false); 
    },

    watch: {
      // Change map bounds according to current zoom (no bounds after minZoom-1)
      userZoom(newVal) {
        if( newVal > this.polygonShowZoomThreshold){
          this.$store.dispatch('setShowPolygons', true); 
        } else {
          this.$store.dispatch('setShowPolygons', false); 
        }

        this.checkMapBoundsInKm();
        this.map.setZoom(newVal);

        if(newVal > 5){
          this.map.setMaxBounds(null);
        }else if(newVal === 5){
          this.setMapBounds();
        }
      },

      // Change shape value to localStorage if new shape is drawn(also get the new river name)
      shape(newVal){
        if(newVal){
          this.queryOSMRiverName();
          let previousShape = localStorage.getItem("shapeCoordinates");

          if (previousShape) {
            localStorage.removeItem("shapeCoordinates");
            localStorage.setItem("shapeCoordinates", JSON.stringify(newVal.getLatLngs().flat(1)));
          } else {
            localStorage.setItem("shapeCoordinates", JSON.stringify(newVal.getLatLngs().flat(1)));
          }
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .main-map {
    z-index: 0;
  }

  .map-legend {
    display: flex;
    align-items: center;
    padding: .5rem;
    gap: 1rem;
    border-radius: .25rem;
    height: 30px;
    background: hsla(0, 0%, 78%, 0.8);

    .v-icon {
        margin-right: 4px;
        background: rgba(5, 0, 0, 0.76) !important;
        border-radius: 50%;
    }
  }
</style>

