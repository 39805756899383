export default {
  setToken(state, token){
    state.token = token;
  },
  removeToken(state){
    state.token = "";
    state.user = "";
  },
  setCurrentUser(state, userName){
    localStorage.setItem("userName", userName)
    state.currentUser = userName;
  },

  setCurrentUserData(state, userData){
    localStorage.setItem("userData", JSON.stringify(userData))
    state.currentUserData = userData;
  },
}