<template>
  <div class="ml-16 mt-3 mb-3">
    <v-btn 
      color="white"
      class="btn pr-5 font-weight-black text-capitalize font-weight-black primary--text modal-button"
      @click="getAllRivers('')"
    >
      Continue Previous Work
    </v-btn>

    <v-dialog 
      class="stepper-dialog"
      v-model="modalOpen" 
      id="dialog"
      persistent
      max-width="auto"
      width="auto"
      :scrollable="true"
    >
      <ErrorAlert
        :error="error"
        :error-message="errorMessage"
        @error-closed="error=false"
      />
        <v-card elevation="0">
            <v-card-text>

      <v-stepper
        v-model="e1"
        class="stepper-scrollable-content"
        elevation="0"

      >

        <v-stepper-header class="stepper-header" elevation="0">
          <v-stepper-step
            :step="e1"
          >
            {{e1 === 1 ? "Select registered river" : e1 === 2 ? "Set the info" : ""}}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>

          <v-stepper-content
            v-for="(step, index) in steps"
            :key="index"
            :step="index + 1"
          >
            
          <v-autocomplete
            v-if="e1 === 1 && rivers?.length > 0"
            v-model="selectedRiver"
            clearable
            :items="rivers"
            item-text="river_name"
            item-value="river_id"
            label="Select river"
            color="primary"
          ></v-autocomplete>
          
          <!-- Step 2: Select a polygon of this river -->
          <v-select
            v-if="e1 === 1"
            v-model="selectedPolygonId"
            :items="polygonDropdownItems"
            :loading="getPolygonsByRiverIdStatus_Pending"
            :disabled="getPolygonsByRiverIdStatus_Pending || !selectedRiver"
            item-text="polygon_name"
            item-value="polygon_id"
            label="Select polygon"
            color="primary"
            @input="getAvailableDatesByPolygonId"
          >
          </v-select>   

          <!-- Step 3: If there are dates with no image/metadata, select a date -->
          <v-select
            v-if="e1 === 1"
            v-model="selectedDates"
            label="Select date from existing date range"
            :items="dates"
            item-text="date"
            :disabled="getPolygonsByRiverIdStatus_Pending || !selectedPolygonId"
            multiple
            chips
            deletable-chips
            hide-details
            color="primary"
            :menu-props="{
              closeOnContentClick: false,
              bottom: true,
              offsetY: true
            }"
          >

          <!-- Select all button -->
          <template v-slot:prepend-item>
            <v-list-item
                ripple
                @mousedown.prevent
                @click="toggleAllDatesSelection"
                >
                <v-list-item-action class="mr-2">
                    <v-icon :color="selectedDates.length > 0 ? 'primary' : ''">
                    {{ selectAllDatesIcon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                    Select All
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>

            <!-- Select Item -->
            <template slot="item" slot-scope="data">
                <v-list-item @click=toggleDateSelection(data.item)>
                    <!-- Checbox for item (multiselect) -->
                    <v-list-item-action class="mr-2">
                        <v-checkbox :input-value="selectedDates.includes(data.item)"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <!-- Content with chip -->
                        <div class="d-flex align-center">
                            <span>{{ data.item }}</span>
                            <v-chip small class="ml-5" color="primary" v-if="isDateSaved(data.item)">Saved</v-chip>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <!-- Inner Selection -->
            <template #selection="{ attrs, index, item, select, selected }">
                <!-- Selected item - closable chip with append-start icon -->
                <v-chip
                    v-if="index <= 3"    
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="selectedDates.splice(selectedDates.indexOf(item), 1)">
                    <v-icon small class="mr-2" color="primary" v-if="isDateSaved(item)">mdi-checkbox-marked-circle</v-icon>
                    {{ item }}
                </v-chip>

                <!-- Rest of selected items -->
                <span
                    v-if="index === 4 && selectedDates"
                    class="grey--text text-caption"
                    >
                    (+{{ selectedDates.length - 4 }} others)
                </span>
                
            </template>
          </v-select>

          <!-- Selection Count -->
          <div v-if="e1 === 1">
              <v-label v-if="selectedDates && dates && selectedPolygonId" class="mt-2 d-flex gx-2 grey--text" :disabled="getPolygonsByRiverIdStatus_Pending || !selectedPolygonId">Selected: {{selectedDates.length}} out of {{dates.length}}</v-label>
              <v-label v-else class="mt-2 d-flex gx-2 grey--text" :disabled="getPolygonsByRiverIdStatus_Pending || !selectedPolygonId">Selected: 0 out of 0</v-label>
          </div>

          <h2
            v-if="rivers?.length == 0"
            class="orange--text text--darken-4"
          >
            No saved rivers yet. Get started by drawing a polygon and setting its image metadata
          </h2>

            <!-- Card to set the metatada for the selected date -->
            <ContinueMetadataModal
              v-else-if="e1 >= 2"
              :polygonId="selectedPolygonId"
              :selectedDates="selectedDates"
              :selectedPolygon="selectedPolygon"
            ></ContinueMetadataModal>
          
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions
              class="ma-0 pa-0"
            >
            <v-col
              cols="12"
              v-if="e1 > 1"
            >
              <v-btn
                text
                color="primary" 
                class="white--text text-capitalize font-weight-bold font-rubik mx-2"
                @click="goToStart()"
              >
              <v-icon left>
                mdi-chevron-left
            </v-icon>
                Back
              </v-btn>
            </v-col>
          
            <v-col
              cols="12"
              class="d-flex justify-end"
              v-if="e1===1 && rivers?.length > 0"
            >
              <v-btn
                color="primary" 
                class="white--text text-capitalize font-weight-bold font-rubik"
                :disabled="!allFilled"
                @click="e1++"
              >
                Next
              </v-btn>
              <v-btn
                color="white" 
                class=" text-capitalize font-weight-bold info--text ml-2 font-rubik"
                @click="closeModal()"
              >
                Close
              </v-btn>
              </v-col>
            </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { withAsync } from             "@/helpers/withAsync";
  import { apiStatus } from             "@/api/constants/apiStatus";
  import { apiStatusComputed } from     "@/api/helpers/computedApiStatus";
  import { getUserPolygons } from "@/api/polygonApi.js";
  import { getUserRivers } from         "@/api/userApi.js";
  import { mapGetters, mapActions } from "vuex";
  import ErrorAlert from                "@/components/ErrorAlert.vue";
  import { required, numeric } from "vuelidate/lib/validators";
  import ContinueMetadataModal from "@/views/RiverTemp/ContinueMetadataModal.vue";

  export default {

    name: "ContinuePreviousWork",

    props:{

      polygonId:{
        type: [Number, String]
      }
    },

    components:{
      ErrorAlert,
      ContinueMetadataModal
    },

    data () {
      return{
        e1: 1,
        steps: [1, 2, 3],
        modalKey: 0,
        modalOpen: false,
        cancelProcedure: false,
        error: false,
        errorMessage: "",
        rivers: null,
        selectedRiver: null,
        // todo possibly can be refactored so selected takes the whole polygons object array and return as well the whole selected object
        polygonDropdownItems: [],
        polygons: null,
        polygonName: null,
        selectedPolygonId: null,
        dates: [],
        selectedDates: [],
        infoMessage: null,
        label: null,
        getUserRiversStatus: apiStatus.Idle,
        getPolygonsByRiverIdStatus: apiStatus.Idle,
      }
    },

    validations: {

      polygonName: { required },
      cloudiness: { required, numeric },
    },

    computed: { 
      ...mapGetters(["getCurrentUserData"]),
      ...apiStatusComputed([
        "getUserRiversStatus", 
        "getPolygonsByRiverIdStatus"
      ]),

      selectedPolygon() {
        return this.polygons.find((polygon)=>polygon.polygon_id === this.selectedPolygonId);
      },

      isPolygonIdValid() {
        return this.selectedPolygonId !== null && this.selectedPolygonId !== undefined && this.selectedPolygonId !== "" && !isNaN(this.selectedPolygonId);
      },

      // "Next" button stays disabled if there is a value missing(river, polygon, date)
      allFilled(){
        return !!(this.selectedDates && this.selectedDates.length && this.selectedPolygonId && this.selectedRiver);
      },

      allDatesSelected () {
        return this.selectedDates.length === this.dates.length
      },

      someDatesSelected () {
        return this.selectedDates.length > 0 && !this.allDatesSelected
      },

      selectAllDatesIcon () {
        if (this.allDatesSelected) return 'mdi-close-box'
        if (this.someDatesSelected) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    }, 

    methods:{
      ...mapActions(["setPolygonId"]),

      openModal() {
        this.modalOpen = true;
        this.cancelProcedure = false;
      },

      closeModal() {
        // const userConfirmed = confirm("Are you sure you want to leave?");
        // if( userConfirmed ) {
          this.resetAll();
          this.modalOpen = false;
        // }
      },

      resetAll(){
        this.cancelProcedure = true;
        this.selectedPolygonId = null;
        this.selectedRiver = null;
        this.writtenRiver = null;
        this.selectedDates = [];
        this.e1 = 1;
        this.steps = [1, 2, 3];
        this.modalKey++;
      },
     
      goToStart(){
        this.e1 = 1;
        this.selectedRiver = null;
        this.writtenRiver = null;
        this.selectedPolygonId = null;
        this.selectedDates = [];
        this.getAllRivers("");
      },

      // Api call to get all registered rivers of the user
      async getAllRivers( name ) {
        this.getUserRiversStatus = apiStatus.Pending;

        const queryParams = {
          name: name 
        };

        const config = {
          params:{
            ...queryParams
          }
        }

        const { response, error } = await withAsync(getUserRivers, config);

        if (error) {
          console.log(error);
          this.error = true;
          this.getUserRiversStatus = apiStatus.Error;
          this.errorMessage = error.response.data.message;
          if (error.response.status == 401) {
            this.$router.push('/login');
          }
          return this.errorMessage;
        }

        this.getUserRiversStatus = apiStatus.Success;
        this.error = false;        
        this.rivers = response.data.data;
        this.modalOpen = true;
      },

      // Api call to get all the polygons of the selected river
      async getUserPolygonsByRiverId(riverId){
        this.getPolygonsByRiverIdStatus = apiStatus.Pending;
        const {response, error} = await withAsync(getUserPolygons, riverId);
        if( error ) {       
          this.error = true;
          this.getPolygonsByRiverIdStatus = apiStatus.Error;
          this.errorMessage = error.response;
          return this.errorMessage;
        }

        this.getPolygonsByRiverIdStatus = apiStatus.Success;

        this.polygons = response.data.data
        // todo selected could accept the whole object
        this.polygonDropdownItems = this.polygons.map(polygon => ({
            polygon_id: polygon.polygon_id,
            polygon_name: polygon.name
          }));
      },
     

      // Filter the dates of the polygon and get only the ones that do not have metadata stored
      getAvailableDatesByPolygonId() {
        if( !this.isPolygonIdValid ) return false;

        // Reset any previous form data.
        this.selectedDates = [];
    
        this.setPolygonId( this.selectedPolygonId );

        // Filter and sort dates
        let polygon = this.polygons.find((polygon) => polygon.polygon_id == this.selectedPolygonId);
        this.dates = polygon.available_dates;

        if (polygon.images.every(image=> image.metadata?.hydrological_condition)) {
          this.infoMessage = "There is no unfinished work for this polygon. Choose another one"
        } else {
          this.infoMessage = null;
        }
      },

      /**
       * Checks if the passed date has saved metadata based on the currently selected polygon.
       * @param {String} date 
       * @returns {Boolean} 
       */
      isDateSaved( selectedDate ) {
        // Getthe polygon that was created on the selectd date.
        let selectedPolygon = this.polygons.find((polygon) => polygon.polygon_id === this.selectedPolygonId);

        if( !selectedPolygon || selectedPolygon.images.length === 0 ) return false;

        // Get the polygon images, generated at the selected date.
        let polygonImages = selectedPolygon.images.filter( image=> image.generated_at === selectedDate );
        
        if( polygonImages.length === 0 ) return false;
        if( !polygonImages[0].metadata?.hydrological_condition ) return false;
        
        return true;
      },

      toggleDateSelection(date) {
        const index = this.selectedDates.indexOf(date);
        if (index === -1) {
            this.selectedDates.push(date);
        } else {
            this.selectedDates.splice(index, 1);
        }
      },

      toggleAllDatesSelection() {
        if( this.allDatesSelected ) {
            this.selectedDates = [];
            } else {
                this.selectedDates = this.dates;
            }
        }
    },

    watch:{

      polygonName(newVal){
        this.$emit("polygon-name", newVal);
      },

      cancelProcedure(newVal){
        if(this.$store.state.Polygons.dates.length == 0 && newVal == true){
          this.e1 = 1;
        }
      },

      error(newVal){
        console.log(newVal)
      },

      selectedRiver(newVal){
        this.selectedPolygonId = null;
        this.selectedDates = [];
        if(newVal !== null){        
          this.getUserPolygonsByRiverId(newVal);
        }
      },

      writtenRiver(newVal){
        this.selectedPolygonId = null;
        this.selectedDates = [];
        if(newVal !== null){
          this.getUserPolygonsByRiverId(newVal.river_id);
        }
      },

      inputName: {
        immediate: true,
        handler(newVal){
          if( newVal === "" ) {
            this.goToStart();
          }

          if( newVal && newVal.length >= 3 ) {
            this.getAllRivers(newVal);
          }
        }
      }
    }
  }
</script>

<style scoped>
  .modal-button {
    z-index: 9999;
  }
  >>> .v-dialog:not(.v-dialog--fullscreen) {
    box-shadow: none !important;
  }

  .stepper-scrollable-content {
    /* overflow-y: auto;
    overflow-x: hidden; */
    /* margin: 20px 0 100px 0; */
  }

>>> .v-dialog--active {
    width: auto;
    min-width: 950px;
  }

  .stepper-header {
    box-shadow: none;
  }

  .font-rubik{
    font-family: 'Rubik', sans-serif;
  }
</style>