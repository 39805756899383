import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import "vuetify/dist/vuetify.min.css"
import "leaflet/dist/leaflet.css"
import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';  
import VueApexCharts from 'vue-apexcharts'
import "@/plugins/global.scss"

Vue.use(Vuelidate);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
