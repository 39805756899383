export default {
  // saves the token to localStorage
  setToken(context, token){
    localStorage.setItem("token", token);
    context.commit("setToken", token);
  },

  setCurrentUser(context, userName){
    context.commit("setCurrentUser", userName);
  },

  setCurrentUserData(context, userData){
    context.commit("setCurrentUserData", userData);
  },

  // removes the userName and token from localStorage
  logout(context){
    if(localStorage.getItem("token") !== null){
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      localStorage.removeItem("userData");
    }
    context.commit("removeToken");
  },
}