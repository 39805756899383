<template>
    <v-row class="justify-space-between align-center">
        <v-col cols="9">
            <v-file-input
                hide-details
                outlined
                v-model="image"
                label="Ground truth image"
                accept="image/png, image/jpeg, image/bmp"
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                :disabled="!!(!selectedDate)"
                @change="previewImage"
                :clearable="false"
            ></v-file-input>
            <!-- @click:clear="resetImageUpload" -->
        </v-col>

        <v-col
            cols="3"
            class="pa-0 d-flex justify-center"
        >
        <v-slide-x-transition>
            <v-img
                v-if="!!preview"
                :src="preview"
                lazy-src="@/assets/image-placeholder.svg"
                contain
                height="100"
                width="100"
                class=""
            />
            <v-img
                v-else
                transition="fade-transition"
                src="@/assets/image-placeholder.svg"
                width="100"
                height="100"
            >
            </v-img>
            </v-slide-x-transition>

        </v-col>
    </v-row>
</template>

<script>
import { CONFIG } from "@/common/config";

export default {
    name: "ImageUpload",
    emits: ["image-uploaded"],

    props: {
        selectedDate: {
            type: String,
            default: null,
            reuired: true,
        },
        existingImage: {
            default: null
        }
    },

    data() {
        return {
            image: null,
            preview: null,
        };
    },

    created() {
        this.preview = this.previewImage(this.existingImage);
    },

    methods: {
        previewImage(input) {
            if (!input) {
                this.resetImageUpload;
                return;
            }

            if (this.isFile(input)) {
           
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                };
                this.image = input;
                reader.readAsDataURL(input);
                this.$emit("image-updated", input);
                return;
            }

            // is path (existing file)
            this.image = null;
            return CONFIG.image_api.concat(input);

        },
        resetImageUpload() {
            this.image = null;
            this.preview = null;
            this.$emit("image-updated", null);
        },

        isFile(file) {
            return typeof File!== 'undefined' && file instanceof File;
        }
    },

    watch: {
        existingImage: function(newVal, oldVal) {
            this.preview = this.previewImage(newVal);
        },
        selectedDate: function(newVal, oldVal) {
            if (!this.existingImage) {
                this.resetImageUpload();
            } else {
                this.preview = this.previewImage(this.existingImage)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>