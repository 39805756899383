export default {
    isAuthenticated(state) {
        return !!state.token;
    },

    getCurrentUser(state) {
        return state.currentUser || localStorage.getItem("userName");
    },

    getCurrentUserData(state) {
        return (
            state.currentUserData ||
            JSON.parse(localStorage.getItem("userData"))
        );
    },

    getCurrentUserId(state) {
        return (
            state.currentUserData.id ||
            JSON.parse(localStorage.getItem("userData")).id
        );
    },

    getUserRole(state) {
        return (
            state.currentUserData.role ||
            JSON.parse(localStorage.getItem("currentUserRole")).role
        );
    },

    hasPermissions: (state) => (userId) => state.currentUserData.id === userId,

    isAdmin(state) {
        return state.currentUserData.role === "admin";
    },
};
