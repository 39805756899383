import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Auth";
import Admin from "./modules/Admin";
import Polygons from "./modules/Polygons";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        namespaced: true,
        Auth,
        Admin,
        Polygons,
    },
});
