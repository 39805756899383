import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  state() {
    return {
      token: localStorage.getItem("token") || sessionStorage.getItem("token") || "",
      user: "",
      currentUser: "",
      currentUserData: JSON.parse(localStorage.getItem("userData")) || "",
      polygonIds:[]
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
